import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import Form from "../forms/form";
import {
  house,
  left,
  right,
  photo,
  title,
  content,
  number,
  linkinfo,
  btnWhite,
  line,
  lineup,
} from "./exhibitionhouse.module.scss";
import { clipPathLineFive, clipPathLineSix } from "./animation";

function Exhibitionhouse() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <motion.section
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.4 }}
      className={house}
    >
      <div className={title}>
        <p className={number}>04</p>
        <h2>/ Приглашаем посетить наши выставочные дома!</h2>
      </div>
      <div className={content}>
        <div className={left}>
          <div className={photo}>
            <StaticImage
              src="../../images/showroom/1.JPG"
              alt="Дом лазовского"
            />
            <div className={linkinfo}>
              <p>д. Афанасово, ул. Ромашковая д.2/2</p>
              <button onClick={() => setOpenForm(true)} className={btnWhite}>
                Записаться на выставку домов
              </button>
            </div>
          </div>
        </div>
        <div className={right}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9329.250308823062!2d37.587890616691524!3d55.95925166477384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b53af9dacd1c11%3A0x2946e622a569d319!2z0KDQvtC80LDRiNC60L7QstCw0Y8g0YPQuy4sIDIsINCQ0YTQsNC90LDRgdC-0LLQviwg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC7LiwgMTI3MjA0!5e0!3m2!1sru!2sru!4v1645176044718!5m2!1sru!2sru"
            width="100%"
            height="100%"
            title="googleMap"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <motion.div variants={clipPathLineFive} className={line} />
      <motion.div variants={clipPathLineSix} className={lineup} />
      <AnimatePresence>
        {openForm && (
          <Form
            titletext="чтобы записаться на выставку домов"
            service="Записаться на выставку домов"
            namebtn="Записаться на выставку"
            idForm="form_exhibition_footer"
            closeForm={() => setOpenForm(false)}
          />
        )}
      </AnimatePresence>
    </motion.section>
  );
}

export default Exhibitionhouse;
