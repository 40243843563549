import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdOutlineEast, MdOutlineWest } from "react-icons/md";
import {
  main,
  slider,
  title,
  number,
  slogan,
  swiper,
  slide,
  slidermob,
  sliderNav,
  next,
  back,
  btnform,
  btnWhite,
} from "./main.module.scss";

import "swiper/css";
import Form from "../forms/form";

function Main() {
  const swiperRef = useRef();
  const swiperMobRef = useRef();
  const [openForm, setOpenForm] = useState(false);

  return (
    <section className={main}>
      <div className={slider}>
        <Swiper
          ref={swiperRef}
          slidesPerView={"auto"}
          spaceBetween={20}
          grabCursor={true}
          className={swiper}
        >
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/main.jpeg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/1.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/2.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={slidermob}>
        <Swiper
          ref={swiperMobRef}
          slidesPerView={"auto"}
          spaceBetween={10}
          grabCursor={true}
          className={swiper}
        >
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/mainmob.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/1m.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <StaticImage
              src="../../images/mainslider/2m.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
              quality={100}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={title}>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className={number}
        >
          01
        </motion.p>
        <motion.h1
          initial={{ y: "-125%" }}
          animate={{ y: "0%" }}
          transition={{ delay: 0.5, type: "spring", stiffness: 50, mass: 0.1 }}
        >
          Свой дом
          <br /> для каждой семьи
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1, type: "spring", stiffness: 75, mass: 0.1 }}
          className={slogan}
        >
          Приглашаем в гости
          <br />
          <span>к нашим клиентам</span>
        </motion.p>
        <div className={sliderNav}>
          <button
            aria-label="предыдущий слайд"
            className={back}
            onClick={() => {
              swiperRef.current.swiper.slidePrev();
              swiperMobRef.current.swiper.slidePrev();
            }}
          >
            <MdOutlineWest />
          </button>
          <button
            aria-label="следующий слайд"
            className={next}
            onClick={() => {
              swiperRef.current.swiper.slideNext();
              swiperMobRef.current.swiper.slideNext();
            }}
          >
            <MdOutlineEast />
          </button>
        </div>
        <div className={btnform}>
          <button className={btnWhite} onClick={() => setOpenForm(true)}>
            Записаться на выставку
          </button>
        </div>
      </div>
      <AnimatePresence>
        {openForm && (
          <Form
            titletext="чтобы записаться на выставку домов"
            service="Записаться на выставку домов"
            namebtn="Записаться на выставку"
            idForm="form_exhibition"
            closeForm={() => setOpenForm(false)}
          />
        )}
      </AnimatePresence>
    </section>
  );
}

export default Main;
