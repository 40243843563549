import React from 'react'

function One() {
  return (
    <svg
      width="100%"
      height="69"
      viewBox="0 0 80 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M24 30.1597H6.06897V41.488H23.8621" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M20.8276 17.9487H16V30.1598H20.8276V17.9487Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M65.1035 17.9487H60.2759V30.1598H65.1035V17.9487Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M67.8621 65.9102H56.9655" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M23.4482 65.9102H12.5517" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M72.1379 68.5583H12.5517V65.9102" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M23.4482 41.7822V68.5583H56.9655V41.7822" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M12.5517 41.7822H8.5517V68.4112H12.5517V41.7822Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M71.862 41.7822H67.8621V68.4112H71.862V41.7822Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M9.37927 15.7419V30.1598H11.7241V14.5649" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M68.6896 14.7119V30.601H71.0345V14.859" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M57.2413 41.7823H73.931V30.6011H57.3793" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M29.1035 49.4326H37.2414" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M42.8965 49.4326H51.0345" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M37.1035 44.8721H29.2414V64.5864H37.1035V44.8721Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M65.1034 46.0488H59.5862V66.0574H65.1034V46.0488Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M51.0345 44.8721H43.1724V64.5864H51.0345V44.8721Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M29.1035 18.6846H37.2414" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M42.8965 18.6846H51.0345" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M23.4482 10.1514V41.7825H56.9655V10.1514" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M37.1035 14.1235H29.2414V33.8378H37.1035V14.1235Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M51.0345 14.1235H43.1724V33.8378H51.0345V14.1235Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M2.34482 18.6843L0.827576 16.1833L40.4138 0.588379L79.3104 15.4477L78.069 18.0958L40.6897 3.53081L2.34482 18.6843Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
    </svg>
  )
}

export default One