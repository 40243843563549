import React from 'react'

function Two() {
  return (
    <svg
      width="78"
      height="49"
      viewBox="0 0 78 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M58.5 49C60.645 49 62.4 47.6219 62.4 45.9375V14.5775C62.4 13.7506 61.971 12.985 61.269 12.4031L46.605 0.888125C45.864 0.336875 44.889 0 43.836 0C40.365 0 38.61 3.3075 41.067 5.23688L54.015 15.4044C54.405 15.68 54.6 16.0781 54.6 16.4762V45.9375C54.6 47.6219 56.355 49 58.5 49ZM74.1 49C76.245 49 78 47.6219 78 45.9375V9.52437C78 8.72812 77.571 7.93187 76.869 7.35L68.679 0.888125C67.938 0.336875 66.924 0 65.91 0C62.4 0 60.684 3.3075 63.141 5.23688L69.654 10.3512C70.005 10.6269 70.2 11.025 70.2 11.4231V45.9375C70.2 47.6219 71.955 49 74.1 49ZM23.4 33.6875C25.545 33.6875 27.3 35.0656 27.3 36.75V49H42.9C45.045 49 46.8 47.6219 46.8 45.9375V19.6306C46.8 18.8037 46.371 18.0381 45.669 17.4562L26.169 2.14375C24.648 0.949374 22.191 0.949374 20.67 2.14375L1.17 17.4562C0.429 18.0381 0 18.8344 0 19.6306V45.9375C0 47.6219 1.755 49 3.9 49H19.5V36.75C19.5 35.0656 21.255 33.6875 23.4 33.6875ZM23.4 27.5625C21.255 27.5625 19.5 26.1844 19.5 24.5C19.5 22.8156 21.255 21.4375 23.4 21.4375C25.545 21.4375 27.3 22.8156 27.3 24.5C27.3 26.1844 25.545 27.5625 23.4 27.5625Z" fill="#525252" />
    </svg>
  )
}

export default Two