import React from 'react'

function Three() {
  return (
    <svg
      width="100%"
      height="59"
      viewBox="0 0 103 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_201_1846)">
        <path d="M18.6675 55.0884H51.3549" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M89.6646 52.6924C90.4491 53.0706 103.001 59.1226 103.001 59.1226L49.9167 59.6269" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M12.653 50.2969C11.7377 50.549 1.53925 57.3575 1.53925 57.3575H19.0597H51.3549" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M54.2314 39.3276H77.2434" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M23.5052 37.4365H12.653" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M12.653 32.5195H23.7667" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M23.2437 46.1361C23.113 46.01 12.653 46.1361 12.653 46.1361V52.6924H23.7667H51.4857" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M12.653 19.4072C14.0913 19.7855 23.2437 19.4072 23.2437 19.4072" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M12.653 14.4897H23.113L23.7667 52.6926L19.0598 56.6011" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M35.2727 57.3579V49.1626" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M31.3502 12.9766V52.6924" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M12.653 9.69873V46.7668" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M44.6867 7.05089C43.9022 6.54656 33.1807 2.7641 33.1807 2.7641L6.76923 11.4638V9.32037L33.0499 0.494629L48.0862 5.91615" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M77.2434 24.5763L31.3502 24.4502V33.0238H77.2434" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M77.2433 14.4897V53.1969H54.7544" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M67.9602 33.0239V18.3984" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M73.5824 33.0239C73.4516 31.2587 73.5824 20.5418 73.5824 20.5418L62.4686 16.1289V32.5196" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M19.0597 57.3579V49.1626H35.2727H51.3549V56.6014V59.6274H19.0597V57.3579Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M54.2314 56.9796V33.5283" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M69.6599 33.5283V52.6928" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M61.8149 33.5283V52.6928" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M45.6019 35.7974H41.2872V45.6318H45.6019V35.7974Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M45.6019 16.1289H39.4567V32.8978H45.6019V16.1289Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
        <path d="M51.6164 56.6014L89.6646 56.3492V18.7768L54.6236 6.29466L26.1202 14.49V12.4727L54.3621 4.27734L94.6331 18.6507L94.3716 20.4158L89.6646 18.7768" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_201_1846">
          <rect width="103" height="59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Three