import React from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const MetaTag = ({ title }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const { siteDescription, siteImage, siteTwitter, siteUrl } =
    site?.siteMetadata;

  const seo = {
    title: title,
    description: siteDescription,
    image: `${siteUrl}${siteImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteTwitter} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <script type="text/javascript">
        {`
      (function(w, d, s, h, id) {
w.roistatProjectId = id; w.roistatHost = h;
var p = d.location.protocol == "https:" ? "https://" : "http://";
var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', '923b1a97611a819b2c2f803915df3ca5');
      `}
      </script>
      <script>
        {`var __cs = __cs || []; __cs.push(["setCsAccount",
        "KBFy9FzPpKO55wlRH9e0t4whMTO_w6p3"])`}
      </script>
      <script
        type="text/javascript"
        async
        src="https://app.uiscom.ru/static/cs.min.js"
      ></script>
      <script type="text/javascript">
        {` (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; 
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(87618141, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });`}
      </script>
    </Helmet>
  );
};

export default MetaTag;

MetaTag.propTypes = {
  title: PropTypes.string,
};
MetaTag.defaultProps = {
  title: "Строительная компания Дом Лазовского",
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteDescription
        siteImage
        siteTwitter
        siteUrl
      }
    }
  }
`;
