import React from 'react'

function Two() {
  return (
    <svg
      width="100%"
      height="71"
      viewBox="0 0 109 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M108.397 70.5669L90.3057 55.8474" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M108.397 63.4958H51.2587" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M108.397 66.8149C108.397 66.8149 90.1549 52.8169 90.1549 52.5283" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M90.3057 49.2092L108.397 63.9287" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M108.397 59.7439H51.2587" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M51.2587 53.5384V70.5669H108.397V59.5994L90.3057 44.8799V55.8474H83.2199" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M2.86444 63.4958H23.0664" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M70.2545 38.9634H47.7911" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M36.7856 39.3962H34.5242" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M36.7856 35.6443H34.5242" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M40.8561 35.6443H38.5947" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M44.9267 35.6443H42.6653" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M70.2545 35.6443H47.7911" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M65.7317 53.5384V44.8799" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M38.1425 53.5384V44.8799" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M70.2545 44.8799H61.2089V53.5384H70.2545V44.8799Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M42.6653 44.8799H33.6196V53.5384H42.6653V44.8799Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M38.1425 31.4593V22.9451" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M42.6653 22.9451H33.6196V31.6036H42.6653V22.9451Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M65.8824 31.4593V22.9451" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M70.4053 22.9451H61.3596V31.6036H70.4053V22.9451Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M70.2545 31.4592H33.6196V44.88H70.2545V31.4592Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M14.9253 48.6321H9.04565V59.0223H14.9253V48.6321Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M20.3527 40.5508C20.3527 40.5508 1.05533 45.0244 0 45.0244" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M2.86444 44.8799V66.8148H108.397" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M20.3527 28.1401V63.4958H83.2199V29.006" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
      <path d="M13.1162 30.4491L52.4648 0.721436L90.3057 30.7377L88.195 32.9023L52.4648 3.75192L14.9253 32.4694L13.1162 30.4491Z" stroke="black" strokeWidth="0.75" strokeMiterlimit="10" />
    </svg>
  )
}

export default Two