// extracted by mini-css-extract-plugin
export var container = "form-module--container--hct9l";
export var closeBtn = "form-module--closeBtn--B6DgX";
export var title = "form-module--title--ZW7pG";
export var desc = "form-module--desc--a74Y9";
export var checkboxForm = "form-module--checkboxForm--+AK6J";
export var check = "form-module--check--z91BB";
export var labelcheck = "form-module--labelcheck--M0Ws2";
export var inputForm = "form-module--inputForm--tYNlF";
export var formSubmit = "form-module--formSubmit--gygoa";
export var politics = "form-module--politics--mFEs0";