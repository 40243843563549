import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import OneFinance from "../svg/financeHome/one";
import TwoFinance from "../svg/financeHome/two";
import Financesvg from "../svg/financesvg";
import Documentsvg from "../svg/documentsvg";
import OneHouse from "../svg/house/one";
import TwoHouse from "../svg/house/two";
import ThreeHouse from "../svg/house/three";
import Form from "../forms/form";
import Formproject from "../forms/formproject";
import { MdOutlineEast } from "react-icons/md";

import {
  mob,
  mobConsult,
  finance,
  plane,
  doklist,
  doktitle,
  bank,
  left,
  right,
  number,
  text,
  blocks,
  item,
  credits,
  btnBlack,
  btnWhite,
  info,
} from "./finance.module.scss";

function Finance() {
  const [openForm, setOpenForm] = useState(false);
  const [openFormProject, setOpenFormProject] = useState(false);

  return (
    <section className={finance} id="finance">
      <button onClick={() => setOpenFormProject(true)} className={btnBlack}>
        Больше проектов <MdOutlineEast />
      </button>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{
          delay: 0.15,
          type: "spring",
          duration: 0.6,
          bounce: 0.15,
          damping: 25,
        }}
        className={plane}
      >
        <div className={left}>
          <p className={number}>03</p>
          <h2>
            Осуществление финансового
            <br /> планирования покупки и реализации
          </h2>
          <div className={mob}>
            <Financesvg />
          </div>
          <p className={text}>
            Помощь в получении государственных субсидий и участия в подходящих
            программах льготной ипотеки.
          </p>

          <div className={blocks}>
            <div className={item}>
              <TwoFinance />
              <p>Подбор оптимальной программы ипотечного кредитования</p>
            </div>
            <div className={item}>
              <OneFinance />
              <p>
                Возможности покупки дома по принципу «трейд-ин» уже имеющейся
                недвижимости
              </p>
            </div>
          </div>
        </div>

        <div className={right}>
          <Financesvg />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{
          delay: 0.15,
          type: "spring",
          duration: 0.6,
          bounce: 0.15,
          damping: 25,
        }}
        className={credits}
      >
        <h2>
          С нами вы получите наиболее выгодный вариант кредитования с учетом
          особенностей вашей сделки
        </h2>
        <div className={info}>
          <div className={left}>
            <p>
              Оформить ипотеку под строительство предлагают в 13 компаниях.
              Наиболее популярными, выгодными и прозрачными по условиям являются
              предложения:
            </p>
          </div>
          <div className={right}>
            <div className={blocks}>
              <div className={item}>
                <StaticImage
                  src="../../images/banks/1.png"
                  layout="fullWidth"
                  alt="логотип ВТБ банк"
                  className={bank}
                />
              </div>
              <div className={item}>
                <StaticImage
                  src="../../images/banks/2.png"
                  layout="fullWidth"
                  alt="логотип сбербанк"
                  className={bank}
                />
              </div>
              <div className={item}>
                <StaticImage
                  src="../../images/banks/3.png"
                  layout="fullWidth"
                  alt="логотип банк"
                  className={bank}
                />
              </div>
              <div className={item}>
                <StaticImage
                  src="../../images/banks/4.png"
                  layout="fullWidth"
                  alt="логотип россельхоз банк"
                  className={bank}
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{
          delay: 0.15,
          type: "spring",
          duration: 0.6,
          bounce: 0.15,
          damping: 25,
        }}
        className={doktitle}
      >
        <div className={left}>
          <h2>
            ОФОРМЛЯЕМ ПОЛНЫЙ ПАКЕТ ДОКУМЕНТОВ, НЕОБХОДИМЫХ ДЛЯ СТРОИТЕЛЬСТВА И
            ПРОЖИВАНИЯ
          </h2>
          <p>Вам остается только заселиться в новое жилье!</p>
        </div>
        <div className={right}>
          <button onClick={() => setOpenForm(true)} className={btnWhite}>
            Консультация <MdOutlineEast />
          </button>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{
          delay: 0.15,
          type: "spring",
          duration: 0.6,
          bounce: 0.15,
          damping: 25,
        }}
        className={doklist}
      >
        <div className={left}>
          <div className={blocks}>
            <div className={item}>
              <TwoHouse />
              <p>
                Оперативно предоставляем отчетность по проводимым
                строительно-монтажным работам
              </p>
            </div>
            <div className={item}>
              <OneHouse />
              <p>
                Проводим правовую экспертизу земельного участка на предмет
                скрытых рисков
              </p>
            </div>
            <div className={item}>
              <ThreeHouse />
              <p>
                Готовим проектную документацию и составляем график строительства
              </p>
            </div>
          </div>
        </div>
        <div className={right}>
          <Documentsvg />
        </div>
      </motion.div>

      <div className={mobConsult}>
        <button onClick={() => setOpenForm(true)} className={btnWhite}>
          Консультация <MdOutlineEast />
        </button>
      </div>
      <AnimatePresence>
        {openForm && (
          <Form
            titletext="чтобы получить консультацию"
            desctext="Наш менеджер Вам перезвонит и подберет удобное для Вам время"
            service="Получить консультацию"
            namebtn="Получить консультацию по ипотеке"
            idForm="form_consultation"
            closeForm={() => setOpenForm(false)}
          />
        )}
        {openFormProject && (
          <Formproject closeForm={() => setOpenFormProject(false)} />
        )}
      </AnimatePresence>
    </section>
  );
}

export default Finance;
