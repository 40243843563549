import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import {
  container,
  inputForm,
  formSubmit,
  title,
  desc,
  politics,
  closeBtn,
} from "./form.module.scss";

function Form({ service, titletext, desctext, namebtn, closeForm, idForm }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const url = "/api/form.php";

  const onSubmit = (data) =>
    axios
      .post(url, {
        data_formname: service,
        data_name: data.name,
        data_phone: data.phone,
      })
      .then(function () {
        closeForm();
        alert("Спасибо за заявку! В ближайшее время мы свяжемся с Вами.");
      });

  return (
    <motion.div
      initial={{ opacity: 0, y: 10, scale: 1.05 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 10, scale: 1.05 }}
      className={container}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <button type="button" className={closeBtn} onClick={closeForm}>
          <MdOutlineClose />
        </button>
        <h2 className={title}>
          Заполните форму,
          <br /> {titletext}
        </h2>
        <p className={desc}>{desctext}</p>
        <div className={inputForm}>
          <p>1. Имя</p>
          <input
            {...register("name", { required: "Обязательное поле" })}
            placeholder="Введите Ваше имя"
            autoComplete="off"
          />
          {errors.name && <span>{errors.name.message}</span>}
        </div>

        <div className={inputForm}>
          <p>2. Номер телефона</p>
          <input
            {...register("phone", { required: "Обязательное поле" })}
            placeholder="Введите Ваш номер телефона"
            autoComplete="off"
          />
          {errors.phone && <span>{errors.phone.message}</span>}
        </div>
        <p className={politics}>
          Оставляя заявку Вы соглашаетесь
          <br />с <Link to="/policy">политикой конфиденциальности</Link>
        </p>
        <div className={formSubmit}>
          <button type="submit">{namebtn}</button>
        </div>
      </form>
    </motion.div>
  );
}

export default Form;
