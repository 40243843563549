export const clipPathLineOne = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 99.5%)'
  },
  onscreen: {
    clipPath: ['inset(0% 0% 100% 99.5%)', 'inset(0% 0% 0% 99%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 0.3,
      duration: 1.75,
      type: 'spring'
    }
  }
};

export const clipPathLineTwo = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 98%)'
  },
  onscreen: {
    clipPath: ['inset(0% 0% 100% 98%)', 'inset(0% 0% 0% 98%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 2.05,
      duration: 1.75,
      type: 'spring'
    }
  }
};

export const clipPathLineThree = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 98%)'
  },
  onscreen: {
    clipPath: ['inset(0% 98% 100% 0%)', 'inset(0% 98% 0% 0%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 3.8,
      duration: 1.75,
      type: 'spring'
    }
  }
};

export const clipPathLineFour = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 98%)'
  },
  onscreen: {
    clipPath: ['inset(0% 0% 100% 0%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 5.4,
      duration: 1,
      type: 'spring'
    }
  }
};

export const clipPathLineFive = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 98%)'
  },
  onscreen: {
    clipPath: ['inset(0% 00 96% 100%)', 'inset(0% 0% 96% 0%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 0.3,
      duration: 1.75,
      type: 'spring'
    }
  }
};

export const clipPathLineSix = {
  offscreen: {
    clipPath: 'inset(0% 0% 100% 98%)'
  },
  onscreen: {
    clipPath: ['inset(100% 0% 0% 0%)', 'inset(0% 0% 0% 0%)'],
    transition: {
      delay: 0.3,
      duration: 1,
      type: 'spring'
    }
  }
};

export const titleShow = {
  offtext: {
    opacity: 0,
    x: 12
  },
  ontext: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.15,
      type: "spring",
      duration: 0.6,
      bounce: 0.15,
      damping: 25
    }
  }
}

export const textShow = {
  offtext: {
    opacity: 0,
    x: 25
  },
  ontext: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.25,
      type: "spring",
      duration: 0.4,
      bounce: 0.15,
      damping: 25
    }
  }
}

export const sliderShow = {
  offscreen: {
    opacity: 0,
    x: 100
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 0.6,
      bounce: 0.15,
      damping: 25
    }
  }
}