import React from 'react'

function One() {
  return (
    <svg
      width="70"
      height="60"
      viewBox="0 0 70 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0.68554 30.2072C1.80848 31.6951 3.85618 31.9657 5.3094 30.8497L8.57912 28.2796V56.6184C8.57912 58.4783 10.0654 60.0001 11.8819 60.0001H58.1204C59.937 60.0001 61.4232 58.4783 61.4232 56.6184V28.2796L64.6929 30.8497C66.1461 31.9995 68.2269 31.6951 69.3168 30.2072C70.4397 28.7192 70.1425 26.5887 68.6893 25.4728L36.9828 0.684797C35.7938 -0.228266 34.1424 -0.228266 32.9865 0.684797L1.28004 25.4728C-0.140149 26.6226 -0.437397 28.753 0.68554 30.2072ZM21.7901 39.7098C19.9736 39.7098 18.4874 38.188 18.4874 36.3281C18.4874 34.4681 19.9736 32.9464 21.7901 32.9464C23.6067 32.9464 25.0929 34.4681 25.0929 36.3281C25.0929 38.188 23.6067 39.7098 21.7901 39.7098ZM35.0012 39.7098C33.1846 39.7098 31.6984 38.188 31.6984 36.3281C31.6984 34.4681 33.1846 32.9464 35.0012 32.9464C36.8177 32.9464 38.3039 34.4681 38.3039 36.3281C38.3039 38.188 36.8177 39.7098 35.0012 39.7098ZM48.2122 39.7098C46.3957 39.7098 44.9094 38.188 44.9094 36.3281C44.9094 34.4681 46.3957 32.9464 48.2122 32.9464C50.0287 32.9464 51.5149 34.4681 51.5149 36.3281C51.5149 38.188 50.0287 39.7098 48.2122 39.7098Z" fill="#525252" />
    </svg>
  )
}

export default One