import React, { useRef, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  line,
  btnWhite,
  linkinfo,
  house,
  title,
  right,
  left,
  one,
  two,
  three,
  photo,
  number,
  header,
  mobileSlider,
  slide,
  swiper,
} from "./house.module.scss";
import { textShow, titleShow, sliderShow } from "./animation";

import "swiper/css";

function House() {
  const oneBlock = useRef();
  const twoBlock = useRef();
  const threeBlock = useRef();
  const [oneHeight, setOneHeight] = useState();
  const [twoHeight, setTwoHeight] = useState();
  const [threeHeight, setThreeHeight] = useState();
  const { scrollY } = useViewportScroll();
  const clipPathOne = useTransform(
    scrollY,
    [200, 300, 500],
    ["inset(0% 0% 100% 99.5%)", "inset(0% 0% 0% 99%)", "inset(0% 0% 0% 0%)"]
  );
  const clipPathTwo = useTransform(
    scrollY,
    [625, oneHeight + 175, oneHeight + 500],
    ["inset(0% 0% 100% 98%)", "inset(0% 0% 0% 98%)", "inset(0% 0% 0% 0%)"]
  );
  const clipPathThree = useTransform(
    scrollY,
    [
      oneHeight + 626,
      oneHeight + 430 + twoHeight - 300,
      oneHeight + twoHeight + 500,
    ],
    ["inset(0% 98% 100% 0%)", "inset(0% 98% 0% 0%)", "inset(0% 0% 0% 0%)"]
  );

  const clipPathFour = useTransform(
    scrollY,
    [oneHeight + twoHeight + 625, oneHeight + twoHeight + 200 + threeHeight],
    ["inset(0% 0% 100% 0%)", "inset(0% 0% 0% 0%)"]
  );

  useEffect(() => {
    setOneHeight(oneBlock.current.clientHeight);
    setTwoHeight(twoBlock.current.clientHeight);
    setThreeHeight(threeBlock.current.clientHeight);
  }, [setOneHeight]);

  return (
    <section className={house} id="houses">
      <div className={header}>
        <div className={title}>
          <h2>/ Наш подход к строительству</h2>
          <p>больше проектов вы найдете в печатном каталоге</p>
        </div>
        <div className={number}>
          <p>02</p>
        </div>
        <motion.div style={{ clipPath: clipPathOne }} className={line} />
      </div>

      <div className={one} ref={oneBlock}>
        <div className={left}>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/1.webp"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                Проект "Еврокуб"
                <br />
                <span>262м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/evrokub/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>Свой дом рядом с Москвой</motion.h3>
            <motion.p variants={textShow}>
              Строим в лучших локациях в 5 км
              <br />
              от Москвы с развитой инфраструктурой
            </motion.p>
          </motion.div>
          <motion.div style={{ clipPath: clipPathTwo }} className={line} />
        </div>
        <div className={right}>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>
              Европейский уровень комфорта
            </motion.h3>
            <motion.p variants={textShow}>
              Применяем качественные материалы
              <br />и проводим все коммуникации
            </motion.p>
          </motion.div>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/2.webp"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                Проект "ГРИНКУБ 2"
                <br />
                <span>230м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/greenkub-2/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
        </div>
      </div>

      <div className={two} ref={twoBlock}>
        <div className={left}>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/3.jpg"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                ПРОЕКТ "ПРОЕКТ «ЕВРОПА»"
                <br />
                <span>213м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/evropa/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>
              Технологичность и энергоэффективность
            </motion.h3>
            <motion.p variants={textShow}>
              Устанавливаем панорамное
              <br />
              остекление с учетом теплоемкости дома
            </motion.p>
          </motion.div>
        </div>
        <div className={right}>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/4.webp"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                Проект "ГРИНКУБ 2.1"
                <br />
                <span>233м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/greenkub-2.1/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>
              Актуальные тренды в строительстве
            </motion.h3>
            <motion.p variants={textShow}>
              Используем лучший опыт архитекторов
              <br />
              Скандинавии и Германии
            </motion.p>
          </motion.div>
          <motion.div style={{ clipPath: clipPathThree }} className={line} />
        </div>
      </div>

      <div className={three} ref={threeBlock}>
        <div className={left}>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/5.webp"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                Проект "ОПТИМА 2"
                <br />
                <span>199м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/optima-2/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>
              Внимание к каждой детали строительства
            </motion.h3>
            <motion.p variants={textShow}>
              Проводим экспертизу участка и получаем
              <br />
              разрешение на строительство дома
            </motion.p>
          </motion.div>
        </div>
        <div className={right}>
          <motion.div
            initial="offtext"
            whileInView="ontext"
            viewport={{ once: true, amount: 1 }}
            className={title}
          >
            <motion.h3 variants={titleShow}>
              Красота и комфорт под ключ
            </motion.h3>
            <motion.p variants={textShow}>
              Благоустраиваем участок, устанавливаем
              <br />
              ворота с электроприводом.
            </motion.p>
          </motion.div>
          <div className={photo}>
            <StaticImage
              src="../../images/houses/6.webp"
              alt="Дом Лазовского"
              layout="fullWidth"
            />
            <motion.div
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              className={linkinfo}
            >
              <p>
                Проект "ОПТИМА"
                <br />
                <span>191м²</span>
              </p>
              <a
                href="https://lazovskiy.ru/doma-tipovie/optima/"
                className={btnWhite}
                target="_blank"
                rel="noreferrer"
              >
                Посмотреть
              </a>
            </motion.div>
          </div>
          <motion.div style={{ clipPath: clipPathFour }} className={line} />
        </div>
      </div>

      <motion.div variants={sliderShow} className={mobileSlider}>
        <Swiper
          slidesPerView={1.2}
          spaceBetween={20}
          grabCursor={true}
          className={swiper}
        >
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/evrokub/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/1.webp"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Свой дом рядом с Москвой</h3>
                <p>
                  Строим в лучших локациях в 5 км от Москвы с развитой
                  инфраструктурой
                </p>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/greenkub-2/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/2.webp"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Европейский уровень комфорта</h3>
                <p>
                  Применяем качественные материалы и проводим все коммуникации
                </p>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/evropa/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/3.jpg"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Технологичность и энергоэффективность</h3>
                <p>
                  Устанавливаем панорамное остекление с учетом теплоемкости дома
                </p>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/greenkub-2.1/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/4.webp"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Актуальные тренды в строительстве</h3>
                <p>
                  Используем лучший опыт архитекторов Скандинавии и Германии
                </p>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/optima-2/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/5.webp"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Внимание к каждой детали строительства</h3>
                <p>
                  Проводим экспертизу участка и получаем разрешение на
                  строительство дома
                </p>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide className={slide}>
            <a
              href="https://lazovskiy.ru/doma-tipovie/optima/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../images/houses/6.webp"
                alt="Дом Лазовского"
              />
              <div className={title}>
                <h3>Красота и комфорт под ключ</h3>
                <p>
                  Благоустраиваем участок, устанавливаем ворота с
                  электроприводом.
                </p>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
      </motion.div>
    </section>
  );
}

export default House;
