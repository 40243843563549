// extracted by mini-css-extract-plugin
export var finance = "finance-module--finance--nMNst";
export var btnBlack = "finance-module--btnBlack--FU4N8";
export var plane = "finance-module--plane--bwUtn";
export var left = "finance-module--left--WIrGO";
export var number = "finance-module--number--rrxfU";
export var mob = "finance-module--mob--c20aM";
export var text = "finance-module--text--QWZKx";
export var blocks = "finance-module--blocks--R6j73";
export var item = "finance-module--item--u7HwS";
export var right = "finance-module--right--5hngX";
export var credits = "finance-module--credits--x0wIE";
export var info = "finance-module--info--548oO";
export var bank = "finance-module--bank--EyotF";
export var doktitle = "finance-module--doktitle--9iI1m";
export var btnWhite = "finance-module--btnWhite--AY+cm";
export var doklist = "finance-module--doklist--5PyHK";
export var mobConsult = "finance-module--mobConsult--xzrUu";