import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import {
  container,
  inputForm,
  formSubmit,
  title,
  checkboxForm,
  check,
  labelcheck,
  politics,
  closeBtn,
} from "./form.module.scss";

function Formproject({ closeForm }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const url = "/api/formproject.php";

  const onSubmit = (data) =>
    axios
      .post(url, {
        data_name: data.name,
        data_surname: data.surname,
        data_contact: data.contact,
        data_email: data.email ? data.email : "",
        data_area: data.area,
        data_finance: data.finance ? data.finance : "",
        data_advertising: data.advertising ? data.advertising : "",
      })
      .then(function () {
        closeForm();
        alert("Спасибо за заявку! В ближайшее время мы свяжемся с Вами.");
      });

  return (
    <motion.div
      initial={{ opacity: 0, y: 10, scale: 1.05 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 10, scale: 1.05 }}
      className={container}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="form_catalog">
        <button type="button" className={closeBtn} onClick={closeForm}>
          <MdOutlineClose />
        </button>
        <h2 className={title}>Куда Вам отправить каталог?</h2>
        <div className={inputForm}>
          <p>1. Имя</p>
          <input
            {...register("name", { required: "Обязательное поле" })}
            placeholder="Введите Ваше имя"
            autoComplete="off"
          />
          {errors.name && <span>{errors.name.message}</span>}
        </div>

        <div className={inputForm}>
          <p>2. Фамилия</p>
          <input
            {...register("surname", { required: "Обязательное поле" })}
            placeholder="Введите Вашу фамилию"
            autoComplete="off"
          />
          {errors.surname && <span>{errors.surname.message}</span>}
        </div>

        <div className={inputForm}>
          <p>3. Телефон</p>
          <input
            {...register("contact", { required: "Обязательное поле" })}
            placeholder="Введите Ваш номер телефона"
            autoComplete="off"
          />
          {errors.contact && <span>{errors.contact.message}</span>}
        </div>

        <div className={inputForm}>
          <p>3. Email</p>
          <input
            {...register("email")}
            placeholder="Введите Ваш email"
            autoComplete="off"
          />
          {errors.email && <span>{errors.email.message}</span>}
        </div>

        <div className={checkboxForm}>
          <p>4. Что Вас интересует?</p>
          <div className={check}>
            <p className={labelcheck}>
              <input {...register("area")} value="Участок" type="checkbox" />
              Участок
            </p>
            <p className={labelcheck}>
              <input
                {...register("area")}
                value="Дом с участком"
                type="checkbox"
              />
              Дом с участком
            </p>
            <p className={labelcheck}>
              <input
                {...register("area")}
                value="Строительство дома на заказ"
                type="checkbox"
              />
              Строительство дома на заказ
            </p>
          </div>
        </div>

        <div className={checkboxForm}>
          <p>5. Дополнительно</p>
          <div className={check}>
            <p className={labelcheck}>
              <input
                {...register("finance")}
                value="Сообщить о вариантах финансирования"
                type="checkbox"
                className={labelcheck}
              />
              Пожалуйста, так же сообщите мне о вариантах финансирования
            </p>
            <p className={labelcheck}>
              <input
                {...register("advertising")}
                value="Получать бесплатную рассылку"
                type="checkbox"
              />
              Я хочу получать бесплатную новостную рассылку
            </p>
          </div>
        </div>

        <p className={politics}>
          Оставляя заявку Вы соглашаетесь
          <br />с <Link to="/policy">политикой конфиденциальности</Link>
        </p>
        <div className={formSubmit}>
          <button type="submit">Заказать каталог бесплатно</button>
        </div>
      </form>
    </motion.div>
  );
}

export default Formproject;
