// extracted by mini-css-extract-plugin
export var main = "main-module--main--qv4r1";
export var slider = "main-module--slider--HlBth";
export var slidermob = "main-module--slidermob---vjqK";
export var swiper = "main-module--swiper---2Qih";
export var slide = "main-module--slide--PeUpe";
export var title = "main-module--title--IcHEt";
export var number = "main-module--number--8tgrX";
export var slogan = "main-module--slogan--ChA0v";
export var sliderNav = "main-module--sliderNav--r1l1e";
export var next = "main-module--next--KX1Wd";
export var back = "main-module--back--1d6wj";
export var btnform = "main-module--btnform--3tsWT";
export var btnWhite = "main-module--btnWhite--F0XHA";