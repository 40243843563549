import React from "react";
import Main from "../components/section/main";
import House from "../components/section/house";
import Finance from "../components/section/finance";
import Exhibitionhouse from "../components/section/exhibitionhouse";
import MetaTag from "../components/metaTag";

function Index() {
  return (
    <>
      <MetaTag />
      <Main />
      <House />
      <Finance />
      <Exhibitionhouse />
    </>
  );
}

export default Index;
