// extracted by mini-css-extract-plugin
export var house = "house-module--house--bfURn";
export var header = "house-module--header--MG8eH";
export var title = "house-module--title--+RnlJ";
export var number = "house-module--number--o2cOn";
export var line = "house-module--line--moPNn";
export var one = "house-module--one--gWbYS";
export var left = "house-module--left--zXisP";
export var photo = "house-module--photo--OwEBw";
export var right = "house-module--right--3LJuL";
export var two = "house-module--two--uLl9r";
export var three = "house-module--three--lUAHc";
export var mobileSlider = "house-module--mobileSlider--xHavX";
export var swiper = "house-module--swiper--vV1Et";
export var slide = "house-module--slide--++m1o";
export var linkinfo = "house-module--linkinfo--1Mw+s";
export var btnWhite = "house-module--btnWhite--yxhT-";