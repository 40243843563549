// extracted by mini-css-extract-plugin
export var house = "exhibitionhouse-module--house--PiQNZ";
export var line = "exhibitionhouse-module--line--17L-z";
export var lineup = "exhibitionhouse-module--lineup--WmNLd";
export var title = "exhibitionhouse-module--title--+bmpC";
export var number = "exhibitionhouse-module--number--PnWV1";
export var content = "exhibitionhouse-module--content--AxPe6";
export var left = "exhibitionhouse-module--left--xUfBh";
export var photo = "exhibitionhouse-module--photo--pHB-D";
export var right = "exhibitionhouse-module--right--yyd8x";
export var linkinfo = "exhibitionhouse-module--linkinfo--rCaDk";
export var btnWhite = "exhibitionhouse-module--btnWhite--6864K";